import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { global as gbbUI, Spinner } from "@grubbe/ui";
import IPDetector from "./IpDetector";

import { BrowserRouter, Routes, Route } from "react-router-dom";
// Pages
const Home = React.lazy(() => import("./pages/home.page"));
const Portfolio = React.lazy(() => import("./pages/portfolio.page"));

const { GlobalStyle } = gbbUI;

//TODO:
//- Textos en inglés
//- Ocultar accesos de Firebase
//- Ejemplo https://github.com/atapas/atapas/blob/main/README.md

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <IPDetector />
    {/* <Home /> */}
    {/* <Portfolio /> */}
    <BrowserRouter>
      <Suspense
        fallback={
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <Spinner />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<App />}>
            {/* <Route index element={<Home />} /> */}
          </Route>
          <Route path="cv" element={<Home />}></Route>
          <Route path="/xp/:project" element={<Portfolio />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
