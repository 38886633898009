import { useEffect } from "react"
import { getDatabase, set, ref } from "firebase/database";
import { app } from "./services/firebase";


const IPDetector = () =>{

  useEffect(()=>{
    getIp().then((v)=>{
      writeUserData(v.ip)
    })
  },[])

  async function getIp(){
    const response = await fetch("https://api.ipify.org?format=json", {
      method: "GET",
      headers:{"Content-Type":"application/json"}
    })
    return response.json()
  }

  function writeUserData(ip : string) {
    var date = new Date()
    var nIp = ip.replace(/[.]/g,"-")
    const db = getDatabase(app);
    set(ref(db, 'IPs/' + nIp), {
      last : date.toString()
    });
  }

  return null
}

export default IPDetector