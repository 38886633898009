// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBRqFaOIFqV0PNrUYyLXA0PVB-v3eKXSLc",
  authDomain: "alexfl.firebaseapp.com",
  databaseURL: "https://alexfl-default-rtdb.firebaseio.com",
  projectId: "alexfl",
  storageBucket: "alexfl.appspot.com",
  messagingSenderId: "157922006534",
  appId: "1:157922006534:web:e3fe216ae9e313ea0f1587",
  measurementId: "G-S30GR9M5TQ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);